import { GetServerSideProps } from 'next'
import { withPublisher } from '@middlewares/tenancy/publisher'
import Layout from '@templates/Layout/Layout'
import { ApplicationPage } from 'src/types/page-props'
import GladlyHelpCenter from '@concepts/FAQ/views/GladlyHelpCenter'
import Seo from '@concepts/Seo/Seo'
import { HELP } from '@concepts/Seo/constants/pages'

const Help: ApplicationPage = () => {
  return (
    <Layout>
      <Seo page={HELP} />
      <GladlyHelpCenter />
    </Layout>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  return withPublisher(context, {}, { fetchSSRUser: false })
}

export default Help
