const container = {
  maxWidth: '60%',
  margin: 'auto'
}

const contactLink = {
  fontWeight: 'bold',
  textDecoration: 'underline'
}

const helpCenter = {
  lineHeight: '1.5',
  input: {
    padding: '10px 20px 10px 20px',
    border: '1px solid #ccc',
    borderRadius: '20px'
  },
  h2: {
    fontSize: 'xl',
    color: 'secondary',
    margin: '20px 0'
  },
  h3: {
    fontWeight: 'bold',
    mb: '10px'
  },
  a: {
    color: 'primary',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  'ul, ol': {
    padding: '20px 0 10px 30px',
    li: {
      mb: '10px'
    }
  },
  ul: {
    listStyleType: 'circle'
  },
  '.gladlyHC-faqSections': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px'
  }
}

export default {
  container,
  contactLink,
  helpCenter
}
