import { useEffect, useRef } from 'react'
import { Box, Text, Link, Heading } from '@lib/UIComponents'
import styles from './styles'
import { supportLinks } from '@utils/supportLinks'

const DEFAULT_TITLE = 'Help Center'
const DEFAULT_BRAND = 'stackCommerce'

export const BRAND_IDS = {
  stackCommerce: '1_jFWZw5QTCpUb-hug3zkw',
  stackSkills: 'L3JhmfvjTxqMuHbAW_yQuA',
  vendorSuccess: '6CjD94bqT6yoHF7AMmmLIA'
}

declare global {
  interface Window {
    gladlyHCConfig: {
      api: string
      orgId: string
      brandId: string
      cdn: string
      selector: string
    }
  }
}

type Props = {
  title?: string
  brand?: keyof typeof BRAND_IDS
}

const GladlyHelpCenter = ({
  title = DEFAULT_TITLE,
  brand = DEFAULT_BRAND
}: Props) => {
  const boxRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    window.gladlyHCConfig = {
      api: 'https://stackcommerce.us-1.gladly.com',
      orgId: 'S_8nv7jeT0-M37jEoMClHQ',
      brandId: BRAND_IDS[brand],
      cdn: 'https://cdn.gladly.com',
      selector: '#gladly-help-center'
    }

    const loadGladlyScript = () => {
      const scriptElement = document.createElement('script')
      scriptElement.type = 'text/javascript'
      scriptElement.async = true
      scriptElement.src = 'https://cdn.gladly.com/help-center/hcl.js'
      boxRef.current?.appendChild(scriptElement)
    }

    loadGladlyScript()
  }, [brand])

  return (
    <Box sx={styles.container}>
      <Heading sx={{ mb: '30px' }}>{title}</Heading>
      <Text as="p" sx={{ mb: '15px' }}>
        Didn't find what you're looking for?{' '}
        <Link href={supportLinks.contact()} sx={styles.contactLink}>
          Contact us.
        </Link>
      </Text>
      <Box ref={boxRef} id="gladly-help-center" sx={styles.helpCenter} />
    </Box>
  )
}

export default GladlyHelpCenter
